import React from "react";
import "./solution.css";
import { Col, Container, Row } from "react-bootstrap";
import withRouter from "../../withRouter";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

function solution(props) {
  return (
    <div className="business-solution">
      <Container>
        <Row>
          <Col
            className="d-flex justify-content-lg-start justify-content-sm-center mt-5"
            lg={6}
            sm={12}
          >
            <div className="leftContent">
              <h1
                className="business-solution-underline fw-bold"
                style={{ color: "#FFCA3D" }}
              >
               {props.t("Biz solution-section heading")}  B2Play 
              </h1>
              {/* <p className="text-white pt-3">
                {props.t("Biz solution-section para1")}
              </p>

              <p className="text-white">
                {props.t("Biz solution-section para2")}
              </p>

              <p className="text-white">
                {props.t("Biz solution-section para3")}
              </p>

              <p className="text-white">
                {props.t("Biz solution-section para4")}
              </p> */}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default withRouter(withTranslation()(solution));
