import React from "react";
import "./adbanner.css";
import Navigation from "../../navbar/Navigation";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import homeIcon from "../../../img/business/bannerVideo/home.png"
import { Link, useLocation } from "react-router-dom";
import withRouter from "../../withRouter";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

function AdBanner(props) {
  
  const location = useLocation();
  return (
    <div className="ad-banner">
      <Navigation />
      <Container className="mt-lg-5 mt-md-5" style={{ marginTop: "25px",padding:"15px" }}>
        <Row>
          <Col sm={12} align="center">
            <h1 className="fw-bold text-white heading">
              B2Play <span style={{ color: "#FFCA3D" }}>{props.t("ADV Page Title")}</span>
            </h1>
            <Breadcrumb className="d-flex justify-content-center">
              <Breadcrumb.Item linkProps={{ to: "/"}} linkAs={Link} >
                <img
                  src={homeIcon}
                  width={14}
                  className="mx-2 align-baseline"
                />
                <span>{props.t("Banner Navbar Homepage")}</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item linkProps={{ to: "/advertise"}} linkAs={Link}  active={`${location.pathname==="/advertise" ? true : false}`}><span>{props.t("Banner Navbar Advertising")}</span></Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
      </Container>
    </div>
  );
}


AdBanner.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(AdBanner));
