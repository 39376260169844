import React, { useState } from "react";
import "./letTalk.css";
import { Button, Col, Container, Form, FormLabel, Row } from "react-bootstrap";
import marathon from "../../../img/home/talk/asset.png";
// import {
//   GoogleReCaptchaProvider,
//   GoogleReCaptcha,
// } from "react-google-recaptcha-v3";
import withRouter from "../../withRouter";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Formik } from "formik";
import * as Yup from "yup";
import FormikController from "../../common/formui/FormikController";
import { useEffect } from "react";
import { useCallback } from "react";
import getCountry from "../../../services/getCountry";
import Spinner from "react-bootstrap/Spinner";
import apiClient from "../../../services/apiClient";
import check from "../../../img/common/check.png";
import { countriesList } from "../../../data/countriesList";

function LetsTalk(props) {
  const [countries, setCountries] = useState(countriesList);
  const [loading, setLoading] = useState(false);
  const initialText = props.t("Form placeholder Send Message");
  const [buttonText, setButtonText] = useState(initialText);
  const [apiCall, setApiCall] = useState(false);

  // const [token, setToken] = useState();
  // const [refreshReCaptcha, setRefreshReCaptcha] = useState(true);

  // const onVerify = useCallback((token) => {
  //   setToken(token);
  // });

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string().required("Email is required").email("Invalid email"),
    subject: Yup.string().required("Subject is required"),
    message: Yup.string().required("Message is required"),
    country: Yup.string().required("Country is required"),
  });

  // const fetchCountries = async () => {
  //   await getCountry
  //     .getCountries()
  //     .then(({ data }) => {
  //       if (data.status) {
  //         setCountries(data.data);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error,"Error");
  //     });
  // };

  // useEffect(() => {
  //   fetchCountries();
  // }, []);


  const handleSubmit = async (values, { resetForm }) => {
    // if(token) {
    resetForm({ values: '' });
    setButtonText(props.t("Form placeholder Sending Messages"))
    setLoading(true);
    const res = await apiClient
      .post("addContactVenueWeb", JSON.stringify(values))
      .then((res) => {
        // setRefreshReCaptcha(r => !r);
        setLoading(false);
      }).catch(error => {
        console.log(error, "Error");
      });
    setButtonText(props.t("Form placeholder Successfully Sent"));
    setApiCall(true);
    setTimeout(() => {
      setApiCall(false);
      setButtonText(initialText);
    }, 3000);
    // }
  };

  return (
    <div className="letTalk" id="contact">
      <Container>
        <Row>
          <Col align="right" xl={6} lg={6}>
            <div className="letTalkWrapper">
              <p className="text-start titile letTalk-underline">
                {props.t("Home lets-talk title")}
              </p>
              <p className="text-start">
                {props.t("Home lets-talk We care about each single email")}
                <br />
                <span style={{ color: "#D94E0C" }}>
                  <b>{props.t("Home lets-talk You + Us = Smarter")}</b>
                </span>
              </p>
            </div>
            <div className="w-100 mt-5 leftColImage">
              <img src={marathon} className="w-100" alt="person runs" />
            </div>
          </Col>
          <Col xl={6} lg={6} className="centerForm">
            <div className="h-100 pt-3 formsWrappers">
              <Formik
                initialValues={{
                  name: "",
                  email: "",
                  subject: "",
                  message: "",
                  country: "",
                }}
                validationSchema={validationSchema}
                enableReinitialize={true}
                onSubmit={handleSubmit}
              >
                {({ handleSubmit, setFieldValue }) => (

                  <Form noValidate onSubmit={handleSubmit}>
                    <FormikController
                      control="input"
                      type="text"
                      label={props.t("Form label Name")}
                      name="name"
                      placeholder={props.t("Form textbox Name Placeholder")}
                    />
                    <FormikController
                      control="input"
                      type="email"
                      label={props.t("Form label Email")}
                      name="email"
                      placeholder={props.t("Form textbox Email Placeholder")}
                    />
                    <FormikController
                      control="select"
                      label={props.t("Form label Country")}
                      name="country"
                      options={countries}
                      showCountryOption={true}

                    // countryApi={true}
                    />

                    <FormikController
                      control="input"
                      type="text"
                      label={props.t("Form label Subject")}
                      name="subject"
                      placeholder={props.t("Form textbox Subject Placeholder")}
                    />
                    <FormikController
                      control="input"
                      style={{ height: "auto" }}
                      label={props.t("Form label Message")}
                      placeholder={props.t("Form textbox Message Placeholder")}
                      name="message"
                      as={"textarea"}
                      rows={2}
                    />

                    {/* <GoogleReCaptchaProvider
                      reCaptchaKey={process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY}
                      container={{
                        element: "google_recaptcha",
                      }}
                    >
                      <GoogleReCaptcha
                        onVerify={onVerify}
                        refreshReCaptcha={refreshReCaptcha}
                      />
                    </GoogleReCaptchaProvider> 
                    <Row>
                      <Col id="google_recaptcha" className="mb-3"></Col>
                    </Row> */}

                    <div>
                      <Button
                        type="submit"
                        className="w-100 rounded-pill border-0 p-2 formBtn"
                      >
                        {loading && <Spinner animation="border" className="me-2" />}
                        {apiCall && <img src={check} width={16} alt="success" className="me-2" />}
                        {buttonText}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
              <div className="w-100 mt-5 rightColImg">
                <img src={marathon} className="w-100" alt="person runs" />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

LetsTalk.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(LetsTalk));
