import React from "react";
import * as Yup from "yup";
import "./partnerforms.css";
import { Formik } from "formik";
import { faEuro } from "@fortawesome/free-solid-svg-icons";
import FormikController from "../common/formui/FormikController";
import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  InputGroup,
  Row,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CalendarFill } from "react-bootstrap-icons";
import Dropzone from "../common/dropzone/Dropzone";
import getCountry from "../../services/getCountry";
import { useState } from "react";
import { useEffect } from "react";
import withRouter from "../withRouter";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import Spinner from "react-bootstrap/Spinner";
import apiClient from "../../services/apiClient";
import FormSuccessMsg from "../common/formSuccessMsg/FormSuccessMsg";
import moment from "moment";
import {countriesList} from "../../data/countriesList";

// import {
//   GoogleReCaptchaProvider,
//   GoogleReCaptcha,
// } from "react-google-recaptcha-v3";
import { useCallback } from "react";

function WorkShop(props) {
  const [modalShow, setModalShow] = useState(false);
  const [countries, setCountries] = useState(countriesList);
  const [loading, setLoading] = useState(false);
  const [reset, setReset] = useState(false);

  // const [token, setToken] = useState();
  // const [refreshReCaptcha, setRefreshReCaptcha] = useState(true);

  // const onVerify = useCallback((token) => {
  //   setToken(token);
  // });

  const phoneRegex = /^\d{10}$/;

  const validationSchema = Yup.object({
    RegistrationType: Yup.string().required("Please select registration type"),
    workshopTitle: Yup.string().required("Class/Workshop Title is required"),
    teacherName: Yup.string().required("Teacher Name is required"),
    phoneNumber: Yup.string()
      .required("Phone number is required")
      .matches(phoneRegex, "Invalid phone number"),
    //   country: Yup.string().required("Please select country"),
    location: Yup.string().required("Location is required"),
    classLevel: Yup.string().required("Level Of Class is required"),
    availabilities: Yup.string().required("Availability is required"),
    startTime: Yup.string().required("Required"),
    endTime: Yup.string().required("Reqired"),
    price: Yup.number()
      .positive("Must be more than 0")
      .integer("Must be more than 0")
      .required("Required"),
    acceptTerms: Yup.bool().oneOf(
      [true],
      "Accept Terms & Conditions is required"
    ),
    teacherPhoto: Yup.array().min(1, "Teachers photos is required"),
    currencyType: Yup.string().required("Currency type is required"),

  });

  const registrationTypeRadio = [
    {
      key: props.t("Form radio Choose Type Registration opt1"),
      value: "Workshop",
    },
    {
      key: props.t("Form radio Choose Type Registration opt2"),
      value: "Class",
    },
  ];

  const startTime = [
    { key: "08:00", value: "08:00" },
    { key: "08:30", value: "08:30" },
    { key: "09:00", value: "09:00" },
    { key: "09:30", value: "09:30" },
    { key: "10:00", value: "10:00" },
    { key: "10:30", value: "10:30" },
    { key: "11:00", value: "11:00" },
    { key: "11:30", value: "11:30" },
    { key: "12:00", value: "12:00" },
    { key: "12:30", value: "12:30" },
    { key: "01:00", value: "01:00" },
    { key: "01:30", value: "01:30" },
    { key: "02:00", value: "02:00" },
    { key: "02:30", value: "02:30" },
    { key: "03:00", value: "03:00" },
    { key: "03:30", value: "03:30" },
    { key: "04:00", value: "04:00" },
    { key: "04:30", value: "04:30" },
    { key: "05:00", value: "05:00" },
    { key: "05:30", value: "05:30" },
    { key: "06:00", value: "06:00" },
    { key: "06:30", value: "06:30" },
    { key: "07:00", value: "07:00" },
    { key: "07:30", value: "07:30" },
  ];

  const endTime = [
    { key: "08:00", value: "08:00" },
    { key: "08:30", value: "08:30" },
    { key: "09:00", value: "09:00" },
    { key: "09:30", value: "09:30" },
    { key: "10:00", value: "10:00" },
    { key: "10:30", value: "10:30" },
    { key: "11:00", value: "11:00" },
    { key: "11:30", value: "11:30" },
    { key: "12:00", value: "12:00" },
    { key: "12:30", value: "12:30" },
    { key: "01:00", value: "01:00" },
    { key: "01:30", value: "01:30" },
    { key: "02:00", value: "02:00" },
    { key: "02:30", value: "02:30" },
    { key: "03:00", value: "03:00" },
    { key: "03:30", value: "03:30" },
    { key: "04:00", value: "04:00" },
    { key: "04:30", value: "04:30" },
    { key: "05:00", value: "05:00" },
    { key: "05:30", value: "05:30" },
    { key: "06:00", value: "06:00" },
    { key: "06:30", value: "06:30" },
    { key: "07:00", value: "07:00" },
    { key: "07:30", value: "07:30" },
  ];

  const AMPM = [
    { key: "AM", value: "am" },
    { key: "PM", value: "pm" },
  ];

  // const fetchCountries = async () => {
  //   await getCountry
  //     .getCountries()
  //     .then(({ data }) => {
  //       if (data.status) {
  //         setCountries(data.data);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error, "Error");
  //     });
  // };

  // useEffect(() => {
  //   fetchCountries();
  // }, []);

  const handleSubmit = async (values, { resetForm }) => {
    // if (token) {
      const headers = {
        "Content-Type": "multipart/form-data",
      };

      let date = moment(values.Date.toLocaleDateString())
        .utc()
        .format("MM-DD-YYYY");

      let startTime = values.startTime + values.startAMPM;
      let endTime = values.endTime + values.endAMPM;
      const newValues = {
        ...values,
        startTime: startTime,
        endTime: endTime,
        Date: date,
      };
      resetForm({ values: "" });
      setReset(true);
      setLoading(true);
      const res = await apiClient
        .post("addWorkShopWeb", newValues, { headers })
        .then((res) => {
          // setRefreshReCaptcha((r) => !r);
          setLoading(false);
          setModalShow(true);
          setTimeout(() => {
            setModalShow(false);
            setReset(false);
          }, 3000);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error, "Error");
        });
    // }
  };

  return (
    <div className="tabs-forms text-start mt-md-5 mt-3">
      <Formik
        initialValues={{
          RegistrationType: "",
          workshopTitle: "",
          teacherName: "",
          phoneNumber: "",
          country: "",
          location: "",
          Date: new Date(),
          roomNameOrNumber: "",
          classLevel: "",
          availabilities: "",
          details: "",
          startTime: "08:00",
          startAMPM: "am",
          endTime: "08:00",
          endAMPM: "am",
          price: "",
          currencyType:"",
          acceptTerms: false,
          teacherPhoto: [],
          placePhoto: [],
        }}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          setFieldValue,
          handleSubmit,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col lg={12}>
                <Form.Label className="required">
                  {props.t("Form radio label Choose Type Registration")}
                </Form.Label>
              </Col>
              <Col lg={12} className="ms-1">
                <FormikController
                  type="radio"
                  control="radio"
                  name="RegistrationType"
                  options={registrationTypeRadio}
                />
              </Col>
              <div className="invalid-feedback">
                {touched.RegistrationType && errors.RegistrationType}
              </div>
            </Row>

            <Row>
              <Col lg={12}>
                <FormikController
                  control="input"
                  type="text"
                  label={props.t("Form label Class-Workshop Title")}
                  name="workshopTitle"
                  placeholder={props.t(
                    "Form textbox Class-Workshop Placeholder"
                  )}
                />
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <FormikController
                  control="input"
                  type="text"
                  label={props.t("Form label Teacher Name")}
                  name="teacherName"
                  placeholder={props.t("Form textbox Teacher Name Placeholder")}
                />
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <FormikController
                  control="input"
                  type="text"
                  label={props.t("Form label Phone Number")}
                  name="phoneNumber"
                  placeholder={props.t("Form text Phone Number Placeholder")}
                />
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <FormikController
                  control="select"
                  label={props.t("Form label Country")}
                  name="country"
                  options={countries}
                  showCountryOption={true}
                  // countryApi={true}
                />
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <FormikController
                  control="input"
                  type="text"
                  label={props.t("Form label Location")}
                  name="location"
                  placeholder={props.t("Form textbox Location Placeholder")}
                />
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <Form.Group controlId="dob">
                  <Form.Label className="required">
                    {props.t("Form label Date")}
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      as={DatePicker}
                      selected={values.Date}
                      onChange={(date) => setFieldValue("Date", date)}
                      className="mb-3"
                    />
                    <div
                      style={{
                        position: "absolute",
                        right: "3%",
                        top: "20%",
                        zIndex: "11",
                      }}
                    >
                      <CalendarFill size={14} />
                    </div>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <FormikController
                  control="input"
                  type="text"
                  label={props.t("Form label Room Name or Number")}
                  name="roomNameOrNumber"
                  placeholder={props.t(
                    "Form textbox Room Name or Number Placeholder"
                  )}
                  notRequired
                />
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <FormikController
                  control="input"
                  type="text"
                  label={props.t("Form label Level of the Class")}
                  name="classLevel"
                  placeholder={props.t(
                    "Form textbox Level of the Class Placeholder"
                  )}
                />
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <FormikController
                  control="input"
                  type="text"
                  label={props.t("Form label Availability")}
                  name="availabilities"
                  placeholder={props.t("Form textbox Availability Placeholder")}
                />
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <FormikController
                  control="input"
                  style={{ height: "auto" }}
                  label={props.t("Form label More Details")}
                  placeholder={props.t("Form textbox More Details Placeholder")}
                  name="details"
                  as={"textarea"}
                  rows={2}
                  notRequired
                />
              </Col>
            </Row>

            <Row>
              <Col lg={2} md={3} xs={6}>
                <FormikController
                  control="select"
                  label={props.t("Form label Start Time")}
                  name="startTime"
                  options={startTime}
                  required
                />
              </Col>
              <Col lg={1} md={2} xs={3} className="px-0 mt-auto">
                <FormikController
                  control="select"
                  name="startAMPM"
                  options={AMPM}
                  className="fw-bold"
                />
              </Col>
              <Col lg={2} md={3} xs={6} className="ms-md-3">
                <FormikController
                  control="select"
                  label={props.t("Form label End Time")}
                  name="endTime"
                  options={endTime}
                  required
                />
              </Col>
              <Col lg={1} md={2} xs={3} className="px-0 mt-auto">
                <FormikController
                  control="select"
                  name="endAMPM"
                  options={AMPM}
                  className="fw-bold"
                />
              </Col>
            </Row>
            <Row>
              <Col lg={4} md={6} xs={12}>
                <FormikController
                  control="input"
                  type="number"
                  label={props.t("Form label Price of the Class-Workshop")}
                  placeholder="0"
                  name="price"
                />
              </Col>
              <Col lg={4} md={6} xs={12}>
                <FormikController
                  control="input"
                  type="text"
                  label={props.t("Form label Currency Type")}
                  placeholder={props.t(
                    "Form textbox Currency-Type Placeholder"
                  )}
                  name="currencyType"
                  className="fw-bold"
                />
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <Form.Label className="required">
                  {props.t("Form label Photo For The Teacher")}
                </Form.Label>
              </Col>
              <Col lg={12}>
                <Dropzone
                  setFieldValue={setFieldValue}
                  placeTeacherError={errors.teacherPhoto}
                  name="teacherPhoto"
                  fromWorkShop={true}
                  reset={reset}
                  maxFiles={1}
                />
                <Form.Control.Feedback type="invalid" className="ms-3 mb-3">
                  {touched.teacherPhoto && errors.teacherPhoto}
                </Form.Control.Feedback>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col lg={12}>
                <Form.Label>
                  {props.t("Form label Photos for Your Place")}
                </Form.Label>
              </Col>
              <Col lg={12}>
                <Dropzone
                  setFieldValue={setFieldValue}
                  placePhotoError={""}
                  name="placePhoto"
                  fromWorkShop={true}
                  reset={reset}
                />
              </Col>
            </Row>
            {/* <GoogleReCaptchaProvider
              reCaptchaKey={process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY}
              container={{
                element: "WorkShop",
              }}
            >
              <GoogleReCaptcha
                onVerify={onVerify}
                refreshReCaptcha={refreshReCaptcha}
              />
            </GoogleReCaptchaProvider>
            <Row>
              <Col id="WorkShop" className="mb-3"></Col>
            </Row> */}
            
            <Row>
              <Col>
                <FormGroup className="mb-4">
                  <Form.Check
                    type={"checkbox"}
                    name="acceptTerms"
                    value={values.acceptTerms}
                    onChange={(e) =>
                      setFieldValue("acceptTerms", e.target.checked)
                    }
                    onBlur={handleBlur}
                    isValid={touched.acceptTerms && !errors.acceptTerms}
                    isInvalid={touched.acceptTerms && errors.acceptTerms}
                    checked={values.acceptTerms}
                    inline
                  />
                  <span
                    className={`text-white ${
                      values.acceptTerms ? `term-condition` : `term`
                    } `}
                  >
                    {props.t("Form i agree to")}
                  </span>{" "}
                  <a
                    href="#"
                    className={`${
                      values.acceptTerms ? `term-condition` : `term`
                    }`}
                  >
                    {props.t("Form terms & conditions")}
                  </a>
                  <Form.Control.Feedback type="invalid" className="ms-3">
                    {touched.acceptTerms && errors.acceptTerms}
                  </Form.Control.Feedback>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  type="submit"
                  className="w-100 rounded-pill border-0 p-3 formBtn mb-5"
                >
                  {loading && <Spinner animation="border" className="me-2" />}
                  {props.t("Form partner form submit")}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
      <FormSuccessMsg show={modalShow} onHide={() => setModalShow(false)} />
    </div>
  );
}

WorkShop.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(WorkShop));
