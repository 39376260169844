import React from "react";
import Banner from "../banner/Banner";
import Navigation from "../navbar/Navigation";
import Footer from "../footer/Footer";
import Solution from "./solution/Solution";
import B2playMiddleSection from "./b2playMiddleSection/B2playMiddleSection";
import Problem from "./theProblem/Problem";
import B2playVideoSection from "./b2playVideoSection/B2playVideoSection";
import LetsTalk from "./talk/LetsTalk";
import Proposition from "./proposition/Proposition";
import ScrollToTop from "../ScrollToTop";

function index() {
  return (
    <>
      <Banner />
      <Proposition />
      <B2playVideoSection />
      <Problem />
      <Solution /> 
      <B2playMiddleSection />
      <LetsTalk />
      <Footer />
    </>
  );
}

export default index;
