import React, { useCallback, useEffect, useState } from "react";
import "./partnerforms.css";
import { Button, Col, Form, FormGroup, InputGroup, Row } from "react-bootstrap";
import { faEuro } from "@fortawesome/free-solid-svg-icons";
import Dropzone from "../common/dropzone/Dropzone";
import { Formik } from "formik";
import * as Yup from "yup";
import FormikController from "../common/formui/FormikController";
import getCountry from "../../services/getCountry";
import FormSuccessMsg from "../common/formSuccessMsg/FormSuccessMsg";
import withRouter from "../withRouter";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import Spinner from "react-bootstrap/Spinner";
import apiClient from "../../services/apiClient";
import {countriesList} from "../../data/countriesList";

// import {
//   GoogleReCaptchaProvider,
//   GoogleReCaptcha,
// } from "react-google-recaptcha-v3";

function SportVenue(props) {
  const [modalShow, setModalShow] = useState(false);
  const [countries, setCountries] = useState(countriesList);
  const [loading, setLoading] = useState(false);
  const [reset, setReset] = useState(false);

  // const [token, setToken] = useState();
  // const [refreshReCaptcha, setRefreshReCaptcha] = useState(true);

  // const onVerify = useCallback((token) => {
  //   setToken(token);
  // });

  const phoneRegex = /^\d{10}$/;

  const validationSchema = Yup.object({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    country: Yup.string().required("Please select country"),
    venueBusinessName: Yup.string().required("Business name is required"),
    email: Yup.string().required("Email is required").email("Invalid email"),
    phoneNumber: Yup.string()
      .required("Phone number is required")
      .matches(phoneRegex, "Invalid phone number"),
    address: Yup.string().required("Address is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    zipCode: Yup.string().required("Postal code is required"),
    stripeAccountId: Yup.string()
      .required("Stripe account id is required")
      .email("Invalid stripe id"),
    aboutVenue: Yup.string().required("About your place is required"),
    sportNames: Yup.string().required("Please select sport provide"),
    currencyType:Yup.string().required("Currency type is required"),
    sportProvideOther: Yup.string().when("sportNames", {
      is: (v) => v === "Other",
      then: (schema) => schema.required("Required"),
    }),
    faclities: Yup.array().min(1, "Please select venue facilities"),
    acceptTerms: Yup.bool().oneOf(
      [true],
      "Accept Terms & Conditions is required"
    ),
    hourlyPrice: Yup.number()
      .positive("Must be more than 0")
      .integer("Must be more than 0")
      .required("Hourly price is required"),
    venueImg: Yup.array().min(1, "Place photos is required"),
    openingTime: Yup.string().required("Opening time is required"),
    closeTime: Yup.string().required("Close time is required"),
  });

  const sportProvideLeftRadio = [
    // { key: props.t("Form radio Sport You Provide opt1"), value: "Loremipsum" },
    { key: props.t("Form radio Sport You Provide opt2"), value: "Tennis" },
    { key: props.t("Form radio Sport You Provide opt3"), value: "BasketBall" },
    { key: props.t("Form radio Sport You Provide opt4"), value: "HandBall" },
    { key: props.t("Form radio Sport You Provide opt5"), value: "TableTennis" },
  ];

  const sportProvideRightRadio = [
    { key: props.t("Form radio Sport You Provide opt6"), value: "Squash" },
    { key: props.t("Form radio Sport You Provide opt7"), value: "Billiard" },
    { key: props.t("Form radio Sport You Provide opt8"), value: "Bowling" },
    { key: props.t("Form radio Sport You Provide opt9"), value: "Swimming" },
    { key: props.t("Form radio Sport You Provide opt10"), value: "Other" },
  ];

  const openTiming = [
    { key: "08:00 AM", value: "08:00AM" },
    { key: "08:30 AM", value: "08:30AM" },
    { key: "09:00 AM", value: "09:00AM" },
    { key: "09:30 AM", value: "09:30AM" },
    { key: "10:00 AM", value: "10:00AM" },
    { key: "10:30 AM", value: "10:30AM" },
    { key: "11:00 AM", value: "11:00AM" },
    { key: "11:30 AM", value: "11:30AM" },
    { key: "12:00 PM", value: "12:00PM" },
    { key: "12:30 PM", value: "12:30PM" },
    { key: "01:00 PM", value: "01:00PM" },
    { key: "01:30 PM", value: "01:30PM" },
    { key: "02:00 PM", value: "02:00PM" },
    { key: "02:30 PM", value: "02:30PM" },
    { key: "03:00 PM", value: "03:00PM" },
    { key: "03:30 PM", value: "03:30PM" },
    { key: "04:00 PM", value: "04:00PM" },
    { key: "04:30 PM", value: "04:30PM" },
    { key: "05:00 PM", value: "05:00PM" },
    { key: "05:30 PM", value: "05:30PM" },
    { key: "06:00 PM", value: "06:00PM" },
    { key: "06:30 PM", value: "06:30PM" },
    { key: "07:00 PM", value: "07:00PM" },
    { key: "07:30 PM", value: "07:30PM" },
    { key: "08:00 PM", value: "08:00PM" },
  ];

  const closeTiming = [
    { key: "08:00 AM", value: "08:00AM" },
    { key: "08:30 AM", value: "08:30AM" },
    { key: "09:00 AM", value: "09:00AM" },
    { key: "09:30 AM", value: "09:30AM" },
    { key: "10:00 AM", value: "10:00AM" },
    { key: "10:30 AM", value: "10:30AM" },
    { key: "11:00 AM", value: "11:00AM" },
    { key: "11:30 AM", value: "11:30AM" },
    { key: "12:00 PM", value: "12:00PM" },
    { key: "12:30 PM", value: "12:30PM" },
    { key: "01:00 PM", value: "01:00PM" },
    { key: "01:30 PM", value: "01:30PM" },
    { key: "02:00 PM", value: "02:00PM" },
    { key: "02:30 PM", value: "02:30PM" },
    { key: "03:00 PM", value: "03:00PM" },
    { key: "03:30 PM", value: "03:30PM" },
    { key: "04:00 PM", value: "04:00PM" },
    { key: "04:30 PM", value: "04:30PM" },
    { key: "05:00 PM", value: "05:00PM" },
    { key: "05:30 PM", value: "05:30PM" },
    { key: "06:00 PM", value: "06:00PM" },
    { key: "06:30 PM", value: "06:30PM" },
    { key: "07:00 PM", value: "07:00PM" },
    { key: "07:30 PM", value: "07:30PM" },
    { key: "08:00 PM", value: "08:00PM" },
  ];

  const firstColumnCheckbox = [
    { key: props.t("Form partner-chk opt1"), value: "5vs5" },
    { key: props.t("Form partner-chk opt2"), value: "6vs6" },
    { key: props.t("Form partner-chk opt3"), value: "7vs7" },
    { key: props.t("Form partner-chk opt4"), value: "8vs8" },
    { key: props.t("Form partner-chk opt5"), value: "9vs9" },
    { key: props.t("Form partner-chk opt6"), value: "10vs10" },
    { key: props.t("Form partner-chk opt7"), value: "11vs11" },
  ];

  const secondColumnCheckbox = [
    { key: props.t("Form partner-chk opt8"), value: "ChangingRooms" },
    {
      key: props.t("Form partner-chk opt9"),
      value: "ChildrenFriendly(Swimming)",
    },
    { key: props.t("Form partner-chk opt10"), value: "Clay(Tennis)" },
    { key: props.t("Form partner-chk opt11"), value: "Concrete(Tennis)" },
    { key: props.t("Form partner-chk opt12"), value: "Court(Basketball)" },
    { key: props.t("Form partner-chk opt13"), value: "DrinkingWater" },
    { key: props.t("Form partner-chk opt14"), value: "FirstAid" },
  ];

  const thirdColumnCheckbox = [
    { key: props.t("Form partner-chk opt15"), value: "Floodlights" },
    { key: props.t("Form partner-chk opt16"), value: "FreeParking" },
    { key: props.t("Form partner-chk opt17"), value: "Grass(Tennis)" },
    { key: props.t("Form partner-chk opt18"), value: "HalfCourt(Basketball)" },
    {
      key: props.t("Form partner-chk opt19"),
      value: "Hard (Madacam)/(Tennis)",
    },
    { key: props.t("Form partner-chk opt20"), value: "Indoor" },
    { key: props.t("Form partner-chk opt21"), value: "Jacuzzi" },
  ];

  const fourthColumnCHeckbox = [
    { key: props.t("Form partner-chk opt22"), value: "Outdoor" },
    { key: props.t("Form partner-chk opt23"), value: "RentalEquipment" },
    { key: props.t("Form partner-chk opt24"), value: "Sauna" },
    { key: props.t("Form partner-chk opt25"), value: "SeatingLounge" },
    { key: props.t("Form partner-chk opt26"), value: "SportsHall(Handball)" },
    { key: props.t("Form partner-chk opt27"), value: "SteamRoom" },
    { key: props.t("Form partner-chk opt28"), value: "Washroom" },
    { key: props.t("Form partner-chk opt29"), value: "Other" },
  ];

  // const fetchCountries = async () => {
  //   await getCountry
  //     .getCountries()
  //     .then(({ data }) => {
  //       if (data.status) {
  //         setCountries(data.data);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error, "Error");
  //     });
  // };

  // useEffect(() => {
  //   fetchCountries();
  // }, []);

  const handleSubmit = async (values, { resetForm }) => {

    // if (token) {
      const headers = {
        "Content-Type": "multipart/form-data",
      };

      const newValues =
        values.sportNames === "Other"
          ? { ...values, sportNames: values.sportProvideOther }
          : { ...values };

      resetForm({ values: "" });
      setReset(true);
      setLoading(true);
      const res = await apiClient
        .post("addSportVenueWeb", newValues, { headers })
        .then((res) => {
          // setRefreshReCaptcha(r => !r);
          setLoading(false);
          setModalShow(true);
          setTimeout(() => {
            setModalShow(false);
            setReset(false);
          }, 3000);
        })
        .catch((error) => {
          console.log(error, "Error");
        });
    // }
  };

  return (
    <div className="tabs-forms text-start">
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          country: "",
          venueBusinessName: "",
          email: "",
          phoneNumber: "",
          address: "",
          city: "",
          state: "",
          zipCode: "",
          stripeAccountId: "",
          aboutVenue: "",
          sportNames: "",
          sportProvideOther: "",
          faclities: [],
          acceptTerms: false,
          hourlyPrice: "",
          currencyType:"",
          venueImg: [],
          openingTime: "08:00AM",
          closeTime: "08:00AM",
        }}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          handleSubmit,
          isSubmitting,
          setFieldTouched,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row align="left">
              <Col md={6} sm={12}>
                <FormikController
                  control="input"
                  type="text"
                  label={props.t("Form label First Name")}
                  name="firstName"
                  placeholder={props.t("Form textbox First Name Placeholder")}
                />
              </Col>
              <Col md={6} sm={12}>
                <FormikController
                  control="input"
                  type="text"
                  label={props.t("Form label Last Name")}
                  name="lastName"
                  placeholder={props.t("Form textbox Last Name Placeholder")}
                />
              </Col>
            </Row>

            <FormikController
              control="select"
              label={props.t("Form label Country")}
              name="country"
              options={countries}
              showCountryOption={true}
              // countryApi={true}
            />

            <FormikController
              control="input"
              type="text"
              label={props.t("Form label Venue Business Name")}
              name="venueBusinessName"
              placeholder={props.t(
                "Form textbox Venue Business Name Placeholder"
              )}
            />

            <FormikController
              control="input"
              type="email"
              label={props.t("Form label E-Mail")}
              name="email"
              placeholder={props.t("Form textbox E-Mail Placeholder")}
            />

            <FormikController
              control="input"
              type="text"
              label={props.t("Form label Phone-Number")}
              name="phoneNumber"
              placeholder={props.t("Form textbox Phone-Number Placeholder")}
            />

            <FormikController
              control="input"
              type="text"
              label={props.t("Form label Address")}
              name="address"
              placeholder={props.t("Form textbox Address Placeholder")}
            />
            <Row className="mb-3">
              <Col lg={4} md={12} sm={12}>
                <FormikController
                  control="input"
                  type="text"
                  label={props.t("Form label City")}
                  name="city"
                  placeholder={props.t("Form textbox partner-city Placeholder")}
                />
              </Col>

              <Col lg={4} md={6} xs={6} className="marginTopClass">
                <FormikController
                  control="input"
                  type="text"
                  label=""
                  name="state"
                  placeholder={props.t("Form textbox state Placeholder")}
                  notRequired
                  noLabel={true}
                />
              </Col>
              <Col lg={4} md={6} xs={6} className="marginTopClass">
                <FormikController
                  control="input"
                  type="text"
                  label=""
                  placeholder={props.t("Form textbox Postal code Placeholder")}
                  name="zipCode"
                  notRequired
                  noLabel={true}
                />
              </Col>
            </Row>

            <FormikController
              control="input"
              type="email"
              label={props.t("Form label Stripe Account ID")}
              placeholder={props.t(
                "Form textbox Stripe Account ID Placeholder"
              )}
              name="stripeAccountId"
            />
            <Row>
              <Col>
                <Form.Label className="required">
                  {props.t("Form radio label Sport You Provide")}
                </Form.Label>
              </Col>
            </Row>
            <Row>
              <Col lg={12} align="left" className="mb-3">
                <div className="d-flex radioButtonGroup">
                  <div className="left">
                    <FormikController
                      type="radio"
                      control="radio"
                      name="sportNames"
                      options={sportProvideLeftRadio}
                    />
                  </div>
                  <div className="right ms-5">
                    <FormikController
                      type="radio"
                      control="radio"
                      name="sportNames"
                      options={sportProvideRightRadio}
                    />

                    {values.sportNames === "Other" && (
                      <FormikController
                        control="input"
                        type="text"
                        label=""
                        placeholder={props.t(
                          "Form radio Sport You Provide opt10"
                        )}
                        name="sportProvideOther"
                        notRequired
                        noLabel={true}
                      />
                    )}
                  </div>
                </div>
                <div>
                  <div className="invalid-feedback">
                    {touched.sportNames && errors.sportNames}
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <Form.Group controlId="sportNames">
                  <Form.Label className="required ">
                    {props.t("Form label chk What your venue Facilities")}
                  </Form.Label>
                </Form.Group>
              </Col>
            </Row>
            <Row className="text-white mb-3">
              <Col xs={5} align="left">
                <div className="d-sm-block d-md-flex">
                  <div className="d-md-block me-lg-5 me-md-2">
                    <div>
                      <FormikController
                        type="checkbox"
                        control="checkbox"
                        name="faclities"
                        options={firstColumnCheckbox}
                      />
                    </div>
                  </div>
                  <div className="d-sm-block ">
                    <div>
                      <FormikController
                        type="checkbox"
                        control="checkbox"
                        name="faclities"
                        options={secondColumnCheckbox}
                      />
                    </div>
                  </div>
                </div>
                <div className="invalid-feedback">
                  {touched.faclities && errors.faclities}
                </div>
              </Col>
              <Col xs={7} align="left">
                <div className="d-sm-block d-md-flex">
                  <div className="d-md-block me-lg-5 me-md-3">
                    <div>
                      <FormikController
                        type="checkbox"
                        control="checkbox"
                        name="faclities"
                        options={thirdColumnCheckbox}
                      />
                    </div>
                  </div>
                  <div className="d-sm-block">
                    <div>
                      <FormikController
                        type="checkbox"
                        control="checkbox"
                        name="faclities"
                        options={fourthColumnCHeckbox}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <FormikController
              control="input"
              style={{ height: "auto" }}
              label={props.t("Form label Please Tell Us About Your Place")}
              placeholder={props.t(
                "Form textbox Please Tell Us About Your Place Placeholder"
              )}
              name="aboutVenue"
              as={"textarea"}
              rows={2}
            />

            <Row>
              <Col lg={6} sm={6}>
                <Row>
                  <Col xs={6}>
                    <FormikController
                      control="select"
                      label={props.t("Form label Opening Time")}
                      name="openingTime"
                      options={openTiming}
                      className="fw-bold"
                      required
                    />
                  </Col>
                  <Col xs={6}>
                    <FormikController
                      control="select"
                      label={props.t("Form label Closing Time")}
                      name="closeTime"
                      options={closeTiming}
                      className="fw-bold"
                      required
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
                {/* <FormikController
                  control="input-icon"
                  type="number"
                  label={props.t("Form label Hourly Price")}
                  placeholder="0"
                  name="hourlyPrice"
                  icon={faEuro}
                  className="fw-bold"
                /> */}
                 <FormikController
                  control="input"
                  type="number"
                  label={props.t("Form label Hourly Price")}
                  placeholder="0"
                  name="hourlyPrice"
                  className="fw-bold"
                />
              </Col>
              <Col lg={6}>
                 <FormikController
                  control="input"
                  type="text"
                  label={props.t("Form label Currency Type")}
                  placeholder={props.t("Form textbox Currency-Type Placeholder")}
                  name="currencyType"
                  className="fw-bold"
                />
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <Form.Label className="required">
                  {props.t("Form label Photos for Your Place")}
                </Form.Label>
              </Col>
              <Col lg={12}>
                <Dropzone
                  setFieldValue={setFieldValue}
                  placeImgesError={errors.venueImg}
                  name="venueImg"
                  fromWorkShop={false}
                  setFieldTouched={setFieldTouched}
                  reset={reset}
                />
                <Form.Control.Feedback type="invalid" className="ms-3 mb-4">
                  {touched.venueImg && errors.venueImg}
                </Form.Control.Feedback>
              </Col>
            </Row>
            {/* <GoogleReCaptchaProvider
              reCaptchaKey={process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY}
              container={{
                element: "SportVenue",
              }}
            >
              <GoogleReCaptcha
                onVerify={onVerify}
                refreshReCaptcha={refreshReCaptcha}
              />
            </GoogleReCaptchaProvider>
            <Row>
              <Col id="SportVenue" className="mb-3"></Col>
            </Row> */}
            <Row>
              <Col>
                <FormGroup className="mb-4">
                  <Form.Check
                    type={"checkbox"}
                    name="acceptTerms"
                    value={values.acceptTerms}
                    onChange={(e) =>
                      setFieldValue("acceptTerms", e.target.checked)
                    }
                    onBlur={handleBlur}
                    isValid={touched.acceptTerms && !errors.acceptTerms}
                    isInvalid={touched.acceptTerms && errors.acceptTerms}
                    checked={values.acceptTerms}
                    inline
                  />
                  
                  <span
                    className={`text-white ${
                      values.acceptTerms ? `term-condition` : `term`
                    } `}
                  >
                    {props.t("Form i agree to")}
                  </span>{" "}
                  <a
                    href="#"
                    className={`${
                      values.acceptTerms ? `term-condition` : `term`
                    }`}
                  >
                    {props.t("Form terms & conditions")}
                  </a>
                  <Form.Control.Feedback type="invalid" className="ms-3">
                    {touched.acceptTerms && errors.acceptTerms}
                  </Form.Control.Feedback>
                </FormGroup>
              </Col>
            </Row>
            
            <Row>
              <Col>
                <Button
                  type="submit"
                  className="w-100 rounded-pill border-0 p-3 formBtn mb-5"
                >
                  {loading && <Spinner animation="border" className="me-2" />}
                  {props.t("Form partner form submit")}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
      <FormSuccessMsg show={modalShow} onHide={() => setModalShow(false)} />
    </div>
  );
}

SportVenue.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SportVenue));
